<template>
  <div v-if="!loading">
    <div class="view-title">
      Total Amps: {{totalAmps}}
    </div>
    <div class="table-title">
      Rides
    </div>
    <b-table
      :items="items"
      responsive
      :fields="fields"
      class="mb-0 tickets-card"
      selectable
      select-mode="single"
      @row-selected="selectRow"
    />
  </div>
  <div class="amps-modal-spinner" v-else>
    <b-spinner label="Loading..."></b-spinner>
  </div>
</template>

<script>

import Moment from 'moment'

export default {
  components: {
  },
  props: {
    customerId: {
      type: String,
      required: true,
    },
    totalAmps: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      fields: [
        {
          label: 'Date',
          key: 'date',
        },
        {
          label: 'Distance',
          key: 'distanceKm',
        },
        {
          label: 'Amps',
          key: 'amps',
        },
      ],
      items: [],
      loading: true,
    }
  },
  mounted() {
    this.load()
  },
  methods: {
    load() {
      this.loading = true
      this.$http.get(this.$resources.customers.points.replace(':id', this.customerId)).then(response => {
        const { data: { status, data } } = response
        if (status === 200) {
          this.items = data.rows.map(c => ({
            date: Moment(c.created_at).format('YYYY-MM-DD HH:mm:ss'),
            rideId: c.ride_id,
            distanceKm: c.km,
            amps: c.points,
          }))
          this.loading = false
        }
      })
    },
    selectRow(rows) {
      this.$router.push(`/rides/${rows[0].rideId}`)
    },
  },
}
</script>
<style lang="scss">
.view-title {
  font-size: 16px;
  margin-top: 10px;
}
.table-title {
  font-weight: 600;
  font-size: 18px;
  margin-top: 15px;
  margin-bottom: 5px;
}
.actions-icons {
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    cursor: pointer;
  }
}
.amps-modal-spinner {
  width: 100%;
  height: 100px;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
}
</style>
