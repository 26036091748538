<template>
  <div
    class="paginator-wrapper mr-1"
  >
    <div class="navigator row">
      <div class="btns">
        <div
          class="navigator-btn back-btn"
          @click="$router.push({ name: target })"
        ><span class="arrow">&lsaquo;</span>
          {{ target }}
        </div>
      </div>
    </div>

    <div
      class="page-title text-center"
    >
      <slot />
      <slot name="is_deleted" />

    </div>
  </div>
</template>
<script>

export default {
  props: {
    navigationData: {
      type: Object,
      default() {
        return null
      },
    },

    updated: {
      type: Boolean,
      default() {
        return false
      },
    },
    target: {
      type: String,
      default() {
        return 'rides'
      },
    },
  },
  data() {
    return {
      pointer: 0,
      startID: 1,
      loading: false,
      navData: this.navigationData || {
        current_item: 1,
        total: 1,
      },
    }
  },
  watch: {
    updated() {
      this.loading = false
    },
    navigationData() {
      this.navData = this.navigationData || this.navData
    },
  },
  mounted() {
    this.startID = localStorage.getItem('selectedRow')
    document.onkeydown = event => {
      switch (event.key) {
        case 'ArrowLeft':
          if (this.navData.current_item <= 1) {
            document.onkeydown = null
            this.$router.push({ name: this.target })
          }
          if (this.navData.current_item > 1) this.navigate(parseInt(this.navData.current_item - 1))
          break
        case 'ArrowRight':
          if (this.navData.current_item < this.navData.total) this.navigate(parseInt(this.navData.current_item + 1))
          break
        default:
          break
      }
    }
  },
  methods: {
    navigate(target) {
      this.loading = true
      this.$route.query.page = parseInt(target)
      this.$emit('navigate', target)
    },
  },
}
</script>
<style lang="scss">

    .paginator-wrapper {
        flex-grow: 1;
        display: grid;
        grid-template-columns: auto 1fr auto;
        .page-title {
          font-size: 20px;
          font-weight: 700;
          line-height: 24px;
          letter-spacing: 0;
          text-align: left;
          color: #151210;
        }

        align-items: center;
        align-content: center;
        .navigator {
            display: grid;
            align-items: center;
            grid-template-columns: 1fr 1fr;
            color: #151210;
            .btns {
                display: flex;
                align-items: center;
                .navigator-btn {
                    cursor: pointer;
                    font-size: 36px;
                    padding: 5px 15px;
                    text-align: center;
                    vertical-align: middle;
                    border: transparent;
                    background-color: transparent;
                    &:hover{
                        color: gray;
                    }
                    &.back-btn {
                        font-size: 20px !important;
                        font-weight: bold;
                        text-transform: capitalize;
                        .arrow {
                            font-size: 32px;
                            line-height: 32px;
                            padding: 5px 15px;
                        }
                    }
                }
                .loader-spinner {
                    margin-top: 5px;
                }
            }
            .informations {
                font-size: 15px;
                padding-top: 7px;
                padding-right: 0;
                font-weight: bold;
            }
        }
        .loader-spinner-wrapper {
            width: 59px;
            text-align: center;
        }
    }
</style>
