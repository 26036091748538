<template>
  <div
    v-if="userDataLoading"
    class="user-card-loader"
  >
    <widget-loader />
  </div>

  <div
    v-else
    class="user-card-component"
  >
    <b-modal
      ref="amps_modal"
      body-class="modal-body"
      modal-class="template-modal amps-modal"
      hide-header
      hide-footer
    >
      <div
        v-b-tooltip="'Close'"
        class="closer"
        @click="closeAmpsModal"
      >
        <img
          class="close-icon-url"
          :src="require(`@/assets/images/icons/menu-icon/popup-icons/close-icon.svg`)"
          alt="close"
        >
      </div>
      <user-amps-history
        :customer-id="customer.id"
        :total-amps="customer.points"
      />
    </b-modal>

    <div class="grid-container">
      <b-card
        class="customer-card default-card col-12 p-0"
      >
        <b-row>
          <b-col
            cols="12"
            lg="3"
            sm="6"
            class="float-left customer-column"
          >
            <b-row>

              <div
                class="customer-image-column"
              >
                <b-avatar
                  rounded
                  size="100px"
                />
              </div>

              <div
                class="name-username-holder"
              >
                <h3
                  class="name link-wrapper"
                  @click="$router.push({name: 'view-customer', params: { id: customer.id }})"
                >
                  {{ customer.first_name }}
                </h3>
                <span
                  class="username d-block font-weight-light link-wrapper"
                  @click="$router.push({name: 'view-customer', params: { id: customer.id }})"
                >{{ customer.username }}</span>
              </div>
            </b-row>

            <b-row>
              <b-col class="column-start btns-columns">
                <b-row>
                  <b-col
                    class="mt-1"
                  >
                    <b-button
                      v-if="$ableTo('View Customers')"
                      v-b-tooltip="'Reset password'"
                      class="user-btn white-background reset-password-btn"
                      @click="resetPassword"
                    >
                      Reset Password
                    </b-button>

                    <span v-if="$ableTo('View Customers')">
                      <b-button
                        v-if="!customer.is_blocked"
                        v-b-tooltip="'Block User'"
                        class="user-btn light-gray-btn block-user-btn"
                        variant="light-success"
                        @click="LockCustomer"
                      >
                        Block User
                      </b-button>
                      <b-button
                        v-else
                        v-b-tooltip="'Unblock User'"
                        class="user-btn light-danger-btn unblock-user-btn"
                        variant="light-danger"
                        @click="UnLockCustomer"
                      >
                        UNBlock User
                      </b-button>
                    </span>

                  </b-col>
                </b-row>
              </b-col>
            </b-row>

          </b-col>

          <b-col
            cols="2"
            class="rides-distance-column"
          >
            <b-row class="customer-utilities-icon-row">
              <span class="customer-utilities-icon float-left">
                <img src="@/assets/images/icons/rides-1.svg">
              </span>
              <span class="float-left">
                <div class="icon-text">{{ customer.rides }}</div>
                <div class="label font-small-1">Rides</div>
              </span>
            </b-row>

            <b-row class="mt-1 customer-utilities-icon-row">
              <span class="customer-utilities-icon float-left">
                <img src="@/assets/images/icons/distance-1.svg">
              </span>
              <span class="float-left">
                <div class="icon-text">{{ customer.distance }}KM</div>
                <div class="label font-small-1">Distance</div>
              </span>
            </b-row>
          </b-col>

          <b-col
            lg="7"
            class="mb-1 bold-text customer-information p-0"
          >
            <b-row
              class="customer-information-column"
            >

              <b-col
                cols="6"
              >
                <ul class="list-unstyled p-0">
                  <li class="customer-information-column-item">
                    <span>
                      <span class="label">
                        Status:
                      </span>
                      <b-badge
                        v-if="!customer.is_blocked"
                        v-b-tooltip="'Block Customer'"
                        class="status-text customer-badge link-wrapper"
                        variant="light-success"
                        @click="LockCustomer"
                      >
                        Active
                      </b-badge>
                      <b-badge
                        v-else
                        v-b-tooltip="'Unblock Customer'"
                        class="customer-badge link-wrapper"
                        variant="light-danger"
                        @click="UnLockCustomer"
                      >
                        Block
                      </b-badge>
                    </span>
                  </li>

                  <li
                    class="customer-information-column-item"
                  >
                    <b-row no-gutters>
                      <div
                        class="email-holder"
                      >
                        <span class="label">Email:</span>
                      </div>
                      <b-col
                        sm="9"
                      >
                        <div
                          id="emails-menu-handler"
                          @click="showEmailsMenu = !showEmailsMenu"
                        >
                          <span class="customer-emails-holder d-block">
                            {{ customer.email }}
                            <span
                              v-if="customer.emails_history !== undefined && customer.emails_history.length > 0"
                              class="caret-span float-right"
                            >&#8964;</span>
                          </span>
                        </div>
                      </b-col>

                    </b-row>

                    <b-row
                      v-if="showEmailsMenu && customer.emails_history !== undefined && customer.emails_history.length > 0"
                      class="d-flex email-dropdown-menu  w-100 mt-2-5"
                    >
                      <div class="font-weight-bold d-block w-100 text-left pl-0-75 previous-emails-title">
                        Previous emails:
                      </div>

                      <ul
                        :class="customer.emails_history.length > 4 ? 'scroll-vertical-ul' : 'static-vertical-ul'"
                        class="list-unstyled text-left customer-emails-listing w-100 d-block clearfix"
                      >
                        <li
                          v-for="(email,index) in customer.emails_history"
                          :key="index"
                          class="customer-email-item-link"
                        >
                          {{ email.new_email }}
                        </li>
                      </ul>

                    </b-row>
                  </li>

                  <li class="customer-information-column-item">
                    <span>
                      <span class="label float-left">Shopify:</span>
                      <span
                        v-if="customer.accounts && (parseInt(customer.accounts.ca) || parseInt(customer.accounts.usa) || parseInt(customer.accounts.ca))"
                        class="data"
                      >
                        <ul class="list-inline shopify-icons">

                          <li
                            v-if="parseInt(customer.accounts.ca)"
                            v-b-tooltip="'Canada store'"
                            class="shopify-list-icon"
                          >
                            <a
                              target="_blank"
                              :href="'https://apollo-scooters-canada.myshopify.com/admin/customers/'+customer.accounts.ca"
                            >
                              <b-badge
                                variant="light-primary"
                              >
                                <img
                                  src="@/assets/images/icons/customer/ca.png"
                                >
                                Canada
                              </b-badge>
                            </a>
                          </li>

                          <li
                            v-if="parseInt(customer.accounts.usa)"
                            v-b-tooltip="'Usa store'"
                            class="shopify-list-icon"
                          >
                            <a
                              target="_blank"
                              :href="'https://apollo-mvmt.myshopify.com/admin/customers/'+customer.accounts.usa"
                            >
                              <b-badge
                                variant="light-primary"
                              >
                                <img
                                  src="@/assets/images/icons/customer/usa.png"
                                >
                                USA
                              </b-badge>
                            </a>
                          </li>

                          <li
                            v-if="parseInt(customer.accounts.subs)"
                            v-b-tooltip="'Subscriptions store'"
                            class="shopify-list-icon"
                          >
                            <a
                              target="_blank"
                              :href="'https://rideapollo.myshopify.com/admin/customers/'+customer.accounts.subs"
                            >
                              <b-badge
                                variant="light-primary"
                              >
                                <img
                                  src="@/assets/images/icons/customer/hand.png"
                                >
                                Subscription
                              </b-badge>
                            </a>
                          </li>

                        </ul>
                      </span>
                      <span v-else>
                        <b-badge
                          variant="primary"
                        >
                          None
                        </b-badge>
                      </span>
                    </span>

                  </li>

                  <li class="customer-information-column-item">
                    <span>
                      <span class="label">Country:</span>
                      <span class="data">{{ customer.country && customer.country.name }}</span>
                    </span>
                  </li>
                  <li class="customer-information-column-item">
                    <span>
                      <span class="label">Contact:</span>
                      <span class="data">{{ customer && (customer.phone || '-') }}</span>
                    </span>
                  </li>
                  <li class="customer-information-column-item">
                    <span>
                      <span class="label">Account created:</span>
                      <span class="data">{{ customer && ((customer.joined_date && customer_joined_date) || '-') }}</span>
                    </span>
                  </li>

                  <li class="customer-information-column-item">
                    <span class="label">Amps:</span>
                    <a
                      class="data"
                      @click="$refs.amps_modal.show()"
                    >{{ customer && (customer.points || '-') }}</a>
                  </li>

                </ul>
              </b-col>

              <b-col
                cols="6"
              >
                <ul class="list-unstyled p-0">
                  <li class="customer-information-column-item">
                    <span>
                      <span class="label">User weight:</span>
                      <span class="data">{{ customerWeight }}</span>
                    </span>
                  </li>

                  <li class="customer-information-column-item">
                    <span>
                      <span class="label">Name:</span>
                      <span class="data">{{ customer && (customer.username || '-') }}</span>
                    </span>
                  </li>
                  <li class="customer-information-column-item">
                    <span>
                      <span class="label">SDK:</span>
                      <span class="data">{{ (customer.device && customer.device.sdk || '-') }}</span>
                    </span>
                  </li>
                  <li class="customer-information-column-item">
                    <span>
                      <span class="label">Model:</span>
                      <span class="data">{{ (customer.device && customer.device.model || '-') }}</span>
                    </span>
                  </li>
                  <li class="customer-information-column-item">
                    <span>
                      <span class="label">Device:</span>
                      <span class="data">{{ (customer.device && customer.device.device || '-') }}</span>
                    </span>
                  </li>
                  <li class="customer-information-column-item">
                    <span>
                      <span class="label">Memory:</span>
                      <span class="data">{{ (customer.device && customer.device.ram_av || '-') }}</span>
                    </span>
                  </li>
                  <li class="customer-information-column-item">
                    <span>
                      <span class="label">App version:</span>
                      <span class="data">{{ (customer.device && customer.device.app_version || '-') }}</span>
                    </span>
                  </li>
                </ul>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            v-if="customer.scooter_types && customer.scooter_types.length > 0"
            cols="12"
            class="tags-list column-start"
          >
            <ul class="list-inline tags scooters-list">
              <li
                v-for="(type,index) in customer.scooter_types"
                :key="index"
                class="tag gray-tag"
              >
                {{ type }}
              </li>
            </ul>
          </b-col>
        </b-row>
      </b-card>
    </div>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BAvatar, VBTooltip, BButton, BBadge,
} from 'bootstrap-vue'
import WidgetLoader from '@/views/components/WidgetLoader.vue'
import Moment from 'moment'
import UserAmpsHistory from '@/views/users/components/UserAmpsHistory.vue'

export default {
  components: {
    UserAmpsHistory,
    BCard,
    BBadge,
    BButton,
    BAvatar,
    BCol,
    BRow,
    WidgetLoader,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    autoload: {
      type: Boolean,
      default: true,
    },
    customerData: {
      type: Object,
      default() {
        return {}
      },
    },
    customerID: {
      type: String,
      default() {
        return null
      },
    },
    viewTickets: {
      type: Boolean,
      default: true,
    },
    tracked: {
      type: Boolean,
      default() {
        return false
      },
    },
  },
  data() {
    return {
      userDataLoading: false,
      should_update_firmware: null,
      showPhoneMenu: false,
      showEmailsMenu: false,
      scooters: [],
      scooters_list: {
        'Apollo Air Pro': 'scooter-air.png',
        'Apollo City Pro': 'scooter-city.png',
        'Apollo Air': 'scooter-air.png',
        'Apollo City': 'scooter-city.png',
        'Apollo Air Pro 2022': 'scooter-city.png',
      },
      scooter_icons: [],
      localCustomer: {},
      fields: [
        {
          label: 'Date',
          key: 'date',
        },
        {
          label: 'Status',
          key: 'status',
        },
        {
          label: 'Action',
          key: 'actions',
        },
      ],
      items: [
        //
      ],
    }
  },
  computed: {
    customer() {
      return this.autoload ? this.localCustomer : this.customerData
    },
    customer_joined_date() {
      if (!this.localCustomer.joined_date || this.localCustomer.joined_date === '') return '-'
      return `${Moment(this.localCustomer.joined_date).format('D MMMM YYYY')}`
      // return `${Moment(this.localCustomer.joined_date).format('D MMMM YYYY')} (${Moment(this.localCustomer.joined_date).fromNow()})`
    },
    customerWeight() {
      return (this.localCustomer?.weight?.value && this.localCustomer?.weight?.unit) ? `${this.localCustomer.weight.value} ${this.localCustomer.weight.unit}` : '-'
    },
  },
  mounted() {
    if (this.autoload) this.load()
    this.scooter_icons = Object.values(this.scooters_list)
  },
  created() {
    window.addEventListener('click', this.windowClicked)
  },
  destroyed() {
    window.removeEventListener('click', this.windowClicked)
  },
  methods: {
    LockCustomer() {
      this.$swal({
        title: 'Block Customer?',
        text: 'Would you like to block this customer?',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#FF0000',
        confirmButtonText: 'Yes',
      }).then(result => { // <--
        if (result.value) { // <-- if confirmed
          this.$http.post(this.$resources.customers.unblock, {
            email: this.customer.email,
          }).then(() => {
            this.customer.is_blocked = true
            this.$notify({ type: 'success', title: 'Block Customer', text: 'user blocked successfully' })
          })
        }
      })
    },
    UnLockCustomer() {
      this.$swal({
        title: 'Unblock Customer?',
        text: 'Would you like to unblock this customer?',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#FF0000',
        confirmButtonText: 'Yes',
      }).then(result => { // <--
        if (result.value) { // <-- if confirmed
          this.$http.post(this.$resources.customers.unblock, {
            email: this.customer.email,
          }).then(() => {
            this.customer.is_blocked = false
            this.$notify({ type: 'success', title: 'Activate customer', text: 'User activated successfully' })
          })
        }
      })
    },
    update_frimware(newStatus) {
      this.$http.post(this.$resources.customers.update_firmware.replace(':id', this.customer.id), {
        status: newStatus ? 1 : 0,
      }).then(() => {
        this.customer.should_update_firmware = newStatus
        this.should_update_firmware = newStatus ? 'Enabled' : 'Disabled'
        this.$notify({ type: 'success', title: 'Framework update', text: newStatus ? 'Framework update enabled successfully' : 'Framework update disabled successfully' })
      })
    },

    openLink(link) {
      window.open(link, '_blank')
    },
    sendPin(scooter) {
      this.loading = true
      this.$http.post(this.$resources.scooters.sendPin, {
        scooter_id: scooter.id,
      }).then(() => {
        this.loading = false
        this.$notify({ type: 'success', title: 'Pincode', text: 'Pincode sent successfully' })
      })
    },
    resetPassword() {
      this.loading = true
      this.$http.post(this.$resources.customers.resetPassword, {
        email: this.customer.email,
      }).then(() => {
        this.loading = false
        this.$notify({ type: 'success', title: 'Password', text: 'Password sent successfully' })
      })
    },
    load() {
      this.loading = true
      this.userDataLoading = true
      this.$http.get(this.$resources.customers.fetch.replace(':id', this.customerID)).then(response => {
        const { data: { status, data } } = response
        if (status === 200) {
          this.userDataLoading = false
          this.loading = false
          this.localCustomer = data.customer
          this.should_update_firmware = data.customer.should_update_firmware ? 'Enabled' : 'Disabled'
        }
      })
    },
    windowClicked(event) {
      if (!event.target.classList.contains('customer-emails-holder')
      && !event.target.classList.contains('customer-email-item-link')
      && !event.target.classList.contains('email-dropdown-menu') && !event.target.classList.contains('previous-emails-title')) {
        this.showEmailsMenu = false
      }

      if (!event.target.classList.contains('customer-phones-holder')
       && !event.target.classList.contains('customer-phone-item')
          && !event.target.classList.contains('phone-dropdown-menu')
          && !event.target.classList.contains('previous-phones-titles')) {
        this.showPhoneMenu = false
      }
    },
    closeAmpsModal() {
      this.$refs.amps_modal.hide()
    },
  },
}

</script>
<style lang="scss">
  @import '@/views/shared-components/scss/UserCard.scss';
</style>
